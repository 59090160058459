<template>
  <component :is="resolveLayout">
    <router-view></router-view>
  </component>
</template>

<script>
import {computed} from '@vue/composition-api'
import {useRouter} from '@/utils'
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/Content.vue'

export default {
  components: {
    LayoutBlank,
    LayoutContent,
  },
  metaInfo() {
    return {
      name: 'Masters of MMA (MoMMA Game)',
      meta: [
        {
          title: 'Masters of MMA (MoMMA Game)',
          property: 'og:url',
          content: 'https://mommagame.com/',
          titleTemplate: 'Masters of MMA (MoMMA Game) | %s ',
        },
      ],
    }
  },
  setup() {
    const {route} = useRouter()

    const resolveLayout = computed(() => {
      // Handles initial route
      if (route.value.name === null) return null

      if (route.value.meta.layout === 'blank') return 'layout-blank'

      return 'layout-content'
    })

    return {
      resolveLayout,
    }
  },
  data() {
    return {isMobile: false}
  },
  mounted() {
    this.onResize()
  },
  methods: {
    onResize() {
      this.isMobile = this.$isMobile()
      if (this.isMobile) {
        document.body.classList.remove('desktop')
        document.body.classList.add('mobile')
      } else {
        document.body.classList.remove('mobile')
        document.body.classList.add('desktop')
      }
    },
  },
}
</script>
