<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center mx-auto text-center">
      <router-link
        :to="homeLink"
        class="d-flex align-center text-decoration-none"
      >
        <v-img
          :src="require('@/assets/images/logos/momma_nav_logo.png')"
          max-height="110px"
          max-width="200px"
          alt="logo"
          contain
          eager
          class="app-logo d-md-block d-sm-block d-xs-block mx-auto ma-5 ml-3"
        ></v-img>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list
      id="main-side"
      expand
      shaped
      class="vertical-nav-menu-items pr-5 pt-0 mt-0"
    >
      <v-btn-toggle
        v-if="user"
        class="text-center mx-auto ml-3"
      >
        <span class="notifAlert ma-1">
          <v-btn to="/notifications">
            <v-icon v-if="notifsUnread">{{ icons.mdiBellRingOutline }}</v-icon>
            <v-icon v-else>{{ icons.mdiBellOutline }}</v-icon>
            <v-badge
              v-if="notifsUnread"
              color="success"
              class="mb-4"
              overlap
              bordered
              :content="notifsUnread"
            ></v-badge>
          </v-btn>
        </span>
        <span class="notifAlert ma-1">
          <v-btn to="/news">
            <v-icon v-if="newsUnread.length > 0">{{
                icons.mdiNewspaperVariantMultipleOutline
              }}</v-icon>
            <v-icon v-else>{{ icons.mdiNewspaperVariantOutline }}</v-icon>
            <v-badge
              v-if="newsUnread"
              color="success"
              class="mb-4"
              overlap
              bordered
              :content="newsUnread"
            ></v-badge>
          </v-btn>
        </span>
        <span class="notifAlert msg ma-1">
          <v-tooltip
            right
            :open-on-hover="false"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="showChat = !showChat"
              >
                <v-icon v-if="msgUnread">{{ icons.mdiChatOutline }}</v-icon>
                <v-icon v-else>{{ icons.mdiChatOutline }}</v-icon>
                <v-badge
                  v-if="msgUnread"
                  color="success"
                  class="mb-4"
                  overlap
                  bordered
                  :content="msgUnread"
                ></v-badge>
              </v-btn>
            </template>
            <span>Chat coming soon</span>
          </v-tooltip>
        </span>
      </v-btn-toggle>
      <nav-menu-link
        v-if="user"
        title="Dashboard"
        :to="{ name: 'dashboard' }"
        :icon="icons.mdiHomeOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Pick'ems"
        :to="{ name: 'fight-tournaments'}"
        :icon="icons.mdiFormatListBulletedTriangle"
      ></nav-menu-link>
      <nav-menu-link
        title="Leaderboards"
        :to="{ name: 'leaderboards'}"
        :icon="icons.mdiTrophy"
      ></nav-menu-link>
      <nav-menu-link
        v-if="user"
        title="My Team"
        :to="{ name: 'my-team'}"
        :icon="icons.mdiAccountGroup"
      ></nav-menu-link>
      <nav-menu-link
        title="Scout Fighters"
        :to="{ name: 'scout-fighters'}"
        :icon="icons.mdiAccountSearchOutline"
        class="d-none"
      ></nav-menu-link>
      <nav-menu-link
        v-if="user"
        title="Leagues"
        :to="{ name: 'leagues'}"
        :icon="icons.mdiTable"
      ></nav-menu-link>
      <nav-menu-link
        title="Career Mode"
        :to="{ name: 'career-mode'}"
        :icon="icons.mdiBookAccountOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="user"
        title="Marketplace"
        :to="{ name: 'marketplace'}"
        :icon="icons.mdiAccountCashOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="News"
        :to="{ name: 'news'}"
        :icon="icons.mdiNewspaperVariantOutline "
      ></nav-menu-link>
      <nav-menu-link
        title="Arcade"
        class="disabled"
        :to="{ name: 'mma-arcade-game'}"
        :icon="icons.mdiGamepadVariantOutline"
      ></nav-menu-link>
    </v-list>
    <div
      v-if="!audioClosed"
      id="audioPlayerWrapper"
    >
      <div
        v-if="$isMobile"
        class="desktop-mute-btn"
      >
        <v-icon
          v-if="muted"
          outlined
          large
          @click="toggleMute"
        >
          {{ icons.mdiVolumeOff }}
        </v-icon>
        <v-icon
          v-else
          outlined
          large
          @click="toggleMute"
        >
          {{ icons.mdiVolumeHigh }}
        </v-icon>
      </div>
      <v-btn
        v-if="audioClosBtn"
        id="close-audio"
        @click="closeAudio"
      >
        <v-icon>{{ icons.mdiCloseCircleOutline }}</v-icon>
      </v-btn>
      <audio-player
        id="game-music"
        ref="audioPlayer"
        :audio-list="audioListShuffle.map(elm => elm.url)"
        :before-play="handleBeforePlay"
        theme-color="#eae4f0"
        :show-playback-rate="false"
        :show-volume-button="false"
        :show-progress-bar="false"
      />
      <av-bars
        id="av"
        caps-color="#FFF"
        :bar-color="['#f00', '#ff0', '#34de9f']"
        :caps-height="2"
        :audio-src="music"
        :canv-width="255"
        :canv-height="50"
        :audio-controls="false"
      ></av-bars>
    </div>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiHomeOutline,
  mdiAlphaTBoxOutline,
  mdiEyeOutline,
  mdiCalendar,
  mdiAccountCashOutline,
  mdiTrophy,
  mdiTable,
  mdiFileOutline,
  mdiFormSelect,
  mdiAccountCogOutline,
  mdiAccountSearchOutline,
  mdiAccountGroup,
  mdiGamepadVariantOutline,
  mdiFormatListBulletedTriangle,
  mdiBookAccountOutline,
  mdiRefresh,
  mdiBellOutline,
  mdiBellRingOutline,
  mdiEmailOutline,
  mdiEmailMultipleOutline,
  mdiNewspaperVariantOutline,
  mdiNewspaperVariantMultipleOutline,
  mdiChatOutline,
  mdiChatProcessingOutline,
  mdiCloseCircleOutline,
  mdiVolumeOff,
  mdiVolumeHigh,
} from '@mdi/js'
import NavMenuLink from './components/NavMenuLink.vue'

export default {
  components: {
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
    notifsUnread: Number,
    newsUnread: Number,
  },
  data() {
    return {
      calendarIcon: require('@/assets/images/misc/calendar-icon.png'),
      icons: {
        mdiHomeOutline,
        mdiAlphaTBoxOutline,
        mdiEyeOutline,
        mdiCalendar,
        mdiAccountCashOutline,
        mdiTable,
        mdiFileOutline,
        mdiFormSelect,
        mdiAccountCogOutline,
        mdiTrophy,
        mdiAccountSearchOutline,
        mdiAccountGroup,
        mdiGamepadVariantOutline,
        mdiFormatListBulletedTriangle,
        mdiBookAccountOutline,
        mdiRefresh,
        mdiBellOutline,
        mdiBellRingOutline,
        mdiEmailOutline,
        mdiEmailMultipleOutline,
        mdiNewspaperVariantOutline,
        mdiNewspaperVariantMultipleOutline,
        mdiChatOutline,
        mdiChatProcessingOutline,
        mdiCloseCircleOutline,
        mdiVolumeOff,
        mdiVolumeHigh,
      },
      user: this.$store.state.user,
      msgUnread: 0,
      showChat: false,
      admin: false,
      currentAudioName: '',
      audioListShuffle: [],
      homeLink: '/',
      audioList: [
        {
          name: 'audio1',
          url: require('@/assets/soundtrack/1.The Positive Rock(Main Version).mp3'),
        }, {
          name: 'audio2',
          url: require('@/assets/soundtrack/Magnetic Wave.mp3'),
        }, {
          name: 'audio3',
          url: require('@/assets/soundtrack/Action Blockbuster Sport Rock Trailer main.mp3'),
        }, {
          name: 'audio4',
          url: require('@/assets/soundtrack/Boom Bap Positive.mp3'),
        }, {
          name: 'audio5',
          url: require('@/assets/soundtrack/Epic Sport Trailer.mp3'),
        }, {
          name: 'audio7',
          url: require('@/assets/soundtrack/Blackmoor Ninjas.mp3'),
        }, {
          name: 'audio8',
          url: require('@/assets/soundtrack/Extreme Sport.mp3'),
        }, {
          name: 'audio9',
          url: require('@/assets/soundtrack/Intense Trap Beat.mp3'),
        }, {
          name: 'audio10',
          url: require('@/assets/soundtrack/Epic Pop Sentimental Adventure.mp3'),
        }, {
          name: 'audio11',
          url: require('@/assets/soundtrack/Hip Hop Rock Full.mp3'),
        }, {
          name: 'audio12',
          url: require('@/assets/soundtrack/Villatic - Hotel Lounge Cafe .mp3'),
        }, {
          name: 'audio13',
          url: require('@/assets/soundtrack/Intense Trap Beat.mp3'),
        }, {
          name: 'audio14',
          url: require('@/assets/soundtrack/Technology Background.mp3'),
        }, {
          name: 'audio15',
          url: require('@/assets/soundtrack/Summer Beat.mp3'),
        }, {
          name: 'audio16',
          url: require('@/assets/soundtrack/Trendy Hip Hop.mp3'),
        }, {
          name: 'audio17',
          url: require('@/assets/soundtrack/kornev_music_-_summer_upbeat_tropical_dance.mp3'),
        }, {
          name: 'audio18',
          url: require('@/assets/soundtrack/Boomopera - Fight (2min Edit).mp3'),
        }, {
          name: 'audio19',
          url: require('@/assets/soundtrack/331 - Trailer Sport Action.mp3'),
        },
      ],
      music: '',
      audioWrapper: {
        src: require('@/assets/soundtrack/Blackmoor Ninjas.mp3'),
        volume: 0.1,
        init: false,
      },
      muted: false,
      audioClosed: false,
      audioClosBtn: false,
    }
  },
  mounted() {
    if (this.user) {
      this.homeLink = '/dashboard'
      this.admin = this.user.profile?.admin
      if (this.user.profile?.muted) {
        this.muted = true
      }
    }

    if (!this.$vuetify.breakpoint.mobile && !this.audioClosed) {
      this.creatAudio()
    }
  },
  methods: {
    creatAudio() {
      this.audioListShuffle = this.audioList.sort(() => Math.random() - 0.5)
      this.$nextTick(() => {
        this.audioWrapper = document.getElementById('av')
          .querySelector('audio')
        this.audioWrapper.init = true
        if (this.$cookies.get('audioClosed') !== 'yes') {
          this.playMusic()
        }
        this.$refs.audioPlayer.$refs.audio.addEventListener('pause', () => {
          this.audioWrapper.pause()
          this.audioClosBtn = true
          this.$cookies.set('audioClosed', 'yes')
        })
      })
    },
    playMusic() {
      this.audioClosBtn = false
      this.audioWrapper.volume = 0.05
      this.audioWrapper.play()
      this.$refs.audioPlayer.$refs.audio.volume = 0
      this.$refs.audioPlayer.play()
    },
    toggleMute() {
      this.muted = !this.muted

      const AudioElements = document.getElementsByTagName('audio')
      AudioElements.forEach(element => {
        const audioElement = element
        if (this.muted) {
          audioElement.muted = true
        } else {
          this.playMusic()
          audioElement.muted = false
        }
      })
    },
    closeAudio() {
      this.$cookies.set('audioClosed', 'yes')
      this.audioWrapper.pause()
      this.$refs.audioPlayer.pause()
      this.audioClosed = true
    },
    handleBeforePlay(next) {
      this.audioClosBtn = false
      if (!this.audioWrapper.init) {
        this.creatAudio()
      } else {
        this.$refs.audioPlayer.volume = 0
        this.$refs.audioPlayer.$refs.audio.volume = 0
        this.currentAudioName = this.audioList[this.$refs.audioPlayer.currentPlayIndex].name
        this.audioWrapper.src = this.audioList[this.$refs.audioPlayer.currentPlayIndex].url
        this.audioWrapper.volume = 0.05
        this.audioWrapper.play()
        this.$cookies.set('audioClosed', 'no')
        next() // Start playing
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

#main-side.vertical-nav-menu-items {
  min-height: 600px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;

  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

a.vertical-nav-menu-link.disabled, .notifAlert.msg {
  opacity: 0.7;
  filter: blur(0.8px);
}

div#audioPlayerWrapper {
  position: absolute;
  bottom: 0;
  right: 7px;
  width: 250px;
  opacity: 0.8;
}

.mobile div#audioPlayerWrapper {
  bottom: -49px;
}

div#audioPlayerWrapper:hover {
  opacity: 1;
}

.audio__btn-wrap {
  top: 10px;
}

button#close-audio {
  position: absolute;
  right: -31px;
  top: -20px;
}

.desktop-mute-btn {
  z-index: 1;
  position: absolute;
  top: 4px;
  left: 14px;
  cursor: pointer;
  width: 28px;
  opacity: 0.8;
}
</style>
