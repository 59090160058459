import Vue from 'vue'
import VueRouter from 'vue-router'
import dbFunctions from '@/db'

Vue.use(VueRouter)

/*
{
  path: '/join',
  redirect: '/beta-access',
},
*/

const routes = [{
  path: '/',
  name: 'landing',
  component: () => import('@/views/LandingPage.vue'),
  meta: {
    layout: 'blank',
    public: true,
  },
}, {
  path: '/dashboard',
  name: 'dashboard',
  component: () => import('@/views/Dashboard.vue'),
}, {
  path: '/account-settings',
  name: 'account-settings',
  component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
}, {
  path: '/login',
  name: 'login',
  component: () => import('@/views/pages/Login.vue'),
  meta: {
    layout: 'blank',
    public: true,
  },
}, {
  path: '/password-reset',
  name: 'password-reset',
  component: () => import('@/views/pages/PasswordReset.vue'),
  meta: {
    layout: 'blank',
    public: true,
  },
}, {
  path: '/register',
  name: 'register',
  component: () => import('@/views/pages/Register.vue'),
  props: route => ({leagueCode: route.query.leagueCode}),
  meta: {
    layout: 'blank',
    public: true,
  },
}, {
  path: '/join',
  name: 'join',
  component: () => import('@/views/pages/Register.vue'),
  props: route => ({leagueCode: route.query.leagueCode}),
  meta: {
    layout: 'blank',
    public: true,
  },
}, {
  path: '/onboard',
  name: 'onboard',
  component: () => import('@/views/pages/Onboard.vue'),
}, {
  path: '/my-team',
  name: 'my-team',
  component: () => import('@/views/pages/TeamView.vue'),
}, {
  path: '/edit-team',
  name: 'edit-team',
  component: () => import('@/views/pages/TeamEdit.vue'),
}, {
  path: '/advisor',
  name: 'advisor',
  component: () => import('@/views/pages/TeamPowerUps.vue'), // component: () => import('@/views/pages/Advisor.vue'),
}, {
  path: '/fight-tournaments',
  name: 'fight-tournaments',
  component: () => import('@/views/pages/FightTorno.vue'),
  meta: {
    public: true,
  },
}, {
  path: '/tournaments/ufc-fight-night/:eventId/*',
  name: 'ufc-fight',
  component: () => import('@/views/pages/tournaments-view/UFCEventView.vue'),
  meta: {
    public: true,
  },
}, {
  path: '/tournaments/beta-ufc-fight',
  name: 'next-beta-ufc-fight',
  component: () => import('@/views/pages/tournaments-view/BetaNextUFCPickEm.vue'),
}, {
  path: '/tournaments/momma-fight-night/:eventId*',
  name: 'mfc-fight',
  component: () => import('@/views/pages/tournaments-view/MFCEventView.vue'),
  meta: {
    public: false,
  },
}, {
  path: '/tournaments/ufc-fight',
  name: 'next-ufc-fight',
  component: () => import('@/views/pages/tournaments-view/UFCEventView.vue'),
  meta: {
    public: true,
  },
}, {
  path: '/tournaments/next-arcade-fight',
  name: 'arcade-tournaments',
  component: () => import('@/views/pages/tournaments-view/ArcadeView.vue'),
}, {
  path: '/mma-arcade-game',
  name: 'mma-arcade-game',
  component: () => import('@/views/pages/FightArcade.vue'),
}, {
  path: '/leagues',
  name: 'leagues',
  component: () => import('@/views/pages/Leagues.vue'),
}, {
  path: '/claim-winnings',
  name: 'claim-winnings',
  component: () => import('@/views/pages/ClaimWinnings.vue'),
}, {
  path: '/past-winnings',
  name: 'past-winnings',
  component: () => import('@/views/pages/PastWinnings.vue'),
}, {
  path: '/push-notifications',
  name: 'push-notifications',
  component: () => import('@/views/pages/PushMessages.vue'),
}, {
  path: '/install-mobile-app',
  name: 'install-mobile-app',
  component: () => import('@/views/pages/InstallMobileApp.vue'),
  meta: {
    public: true,
  },
}, {
  path: '/edit-league',
  name: 'edit-leagues',
  component: () => import('@/views/pages/LeagueEdit.vue'),
}, {
  path: '/view-league',
  name: 'view-league',
  component: () => import('@/views/pages/LeagueView.vue'),
}, {
  path: '/recruit-fighters',
  name: 'recruit-fighters',
  component: () => import('@/views/pages/RecruitFighters.vue'),
}, {
  path: '/marketplace',
  name: 'marketplace',
  component: () => import('@/views/pages/RecruitFighters.vue'),
}, {
  path: '/scout-fighters',
  name: 'scout-fighters',
  component: () => import('@/views/pages/ScoutFighters.vue'),
}, {
  path: '/leaderboards',
  name: 'leaderboards',
  component: () => import('@/views/pages/Leaderboards.vue'),
  meta: {
    public: true,
  },
}, {
  path: '/career-mode',
  name: 'career-mode',
  component: () => import('@/views/pages/CareerMode.vue'),
  meta: {
    public: true,
  },
}, {
  path: '/career-mode/gyms',
  name: 'career-mode-gyms',
  component: () => import('@/views/pages/careerMode/GymsList.vue'),
}, {
  path: '/career-mode/hospitals',
  name: 'career-mode-hospitals',
  component: () => import('@/views/pages/careerMode/HospitalsList.vue'),
}, {
  path: '/career-mode/sparring',
  name: 'career-mode-sparring',
  component: () => import('@/views/pages/careerMode/SparringList.vue'),
}, {
  path: '/career-mode/attribute-log',
  name: 'career-mode-attribute-log',
  component: () => import('@/views/pages/careerMode/attributeLog.vue'),
}, {
  path: '/career-mode/attribute-log-admin',
  name: 'career-mode-attribute-log-admin',
  component: () => import('@/views/pages/careerMode/attributeLogAdmin.vue'),
}, {
  path: '/career-mode/vs-v1',
  name: 'career-mode-vs-v1',
  component: () => import('@/views/pages/careerMode/VsView.vue'),
}, {
  path: '/career-mode/mfc-tournaments',
  name: 'mfc-tournaments',
  component: () => import('@/views/pages/MfcTournaments.vue'),
}, {
  path: '/my-fighters',
  name: 'my-fighters',
  component: () => import('@/views/pages/careerMode/MyMommaFighters.vue'),
}, {
  path: '/my-fighter',
  name: 'my-fighter',
  component: () => import('@/views/pages/careerMode/MyFighter.vue'),
}, {
  path: '/wallet',
  name: 'wallet',
  component: () => import('@/views/pages/ConnectWallet.vue'),
}, {
  path: '/team-powerups',
  name: 'team-powerups',
  component: () => import('@/views/pages/TeamPowerUps.vue'),
}, {
  path: '/powerups',
  name: 'powerups',
  component: () => import('@/views/pages/UfcStrike.vue'),
}, {
  path: '/ar-viewer',
  name: 'ar-viewer',
  component: () => import('@/views/pages/ArViewer.vue'),
  meta: {
    layout: 'blank',
    public: true,
  },
}, {
  path: '/chat',
  name: 'chat',
  component: () => import('@/views/pages/ChatFullscreen.vue'),
  meta: {
    layout: 'Chat',
    public: true,
  },
}, {
  path: '/discord/',
  name: 'discord-connect',
  component: () => import('@/views/pages/DiscordConnect.vue'),
  meta: {
    public: false,
  },
}, {
  path: '/connect-wallet',
  name: 'connect-wallet',
  component: () => import('@/views/pages/ConnectWallet.vue'),
}, {
  path: '/notifications',
  name: 'notifications',
  component: () => import('@/views/pages/Notifications.vue'),
}, {
  path: '/contact-us',
  name: 'contact-us',
  component: () => import('@/views/pages/ContactUs.vue'),
  meta: {
    public: true,
  },
}, {
  path: '/logout',
  name: 'logout',
  component: () => import('@/views/pages/LogOut.vue'),
  meta: {
    layout: 'blank',
    public: true,
  },
}, {
  path: '/coming-soon',
  name: 'coming-soon',
  component: () => import('@/views/pages/ComingSoon.vue'),
  meta: {
    layout: 'blank',
    public: true,
  },
}, {
  path: '/beta-access',
  name: 'beta-signup',
  component: () => import('@/views/pages/BetaSignup.vue'),
  props: route => ({leagueCode: route.query.leagueCode}),
  meta: {
    public: true,
  },
}, {
  path: '/privacy-policy',
  name: 'privacy-policy',
  component: () => import('@/views/pages/PrivacyPolicy.vue'),
  meta: {
    public: true,
  },
}, {
  path: '/terms-of-service',
  name: 'terms-of-service',
  component: () => import('@/views/pages/TermsOfService.vue'),
  meta: {
    public: true,
  },
}, {
  path: '/error-404',
  name: 'error-404',
  component: () => import('@/views/Error.vue'),
  meta: {
    public: true,
  },
}, {
  path: '/fkadmin',
  name: 'admin-dashboard',
  component: () => import('@/views/admin/AdminDashboard.vue'),
  meta: {
    admin: true,
  },
}, {
  path: '/fkadmin/members',
  name: 'members',
  component: () => import('@/views/admin/Members.vue'),
  meta: {
    admin: true,
  },
}, {
  path: '/fkadmin/discord-users',
  name: 'discord-users',
  component: () => import('@/views/admin/DiscordUsers.vue'),
  meta: {
    admin: true,
  },
}, {
  path: '/fkadmin/bots',
  name: 'bots',
  component: () => import('@/views/admin/Bots.vue'),
  meta: {
    admin: true,
  },
}, {
  path: '/fkadmin/nfts',
  name: 'nfts',
  component: () => import('@/views/admin/NftAdmin.vue'),
  meta: {
    admin: true,
  },
}, {
  path: '/fkadmin/momma-fighter-admin',
  name: 'momma-fighter-admin',
  component: () => import('@/views/admin/MommaFighterAdmin.vue'),
  meta: {
    admin: true,
  },
}, {
  path: '/fkadmin/momma-coach-admin',
  name: 'momma-coach-admin',
  component: () => import('@/views/admin/MommaCoachAdmin.vue'),
  meta: {
    admin: true,
  },
}, {
  path: '/fkadmin/prizeClaims',
  name: 'prizeClaims',
  component: () => import('@/views/admin/PrizeClaims.vue'),
  meta: {
    admin: true,
  },
}, {
  path: '/fkadmin/picks',
  name: 'picks',
  component: () => import('@/views/admin/Picks.vue'),
  meta: {
    admin: true,
  },
}, {
  path: '/fkadmin/managers',
  name: 'admin-managers',
  component: () => import('@/views/admin/AdminManagers.vue'),
  meta: {
    admin: true,
  },
}, {
  path: '/fkadmin/trainingCamps',
  name: 'trainingCamps',
  component: () => import('@/views/admin/TrainingCamps.vue'),
  meta: {
    admin: true,
  },
}, {
  path: '/fkadmin/sparring',
  name: 'sparring',
  component: () => import('@/views/admin/Sparring.vue'),
  meta: {
    admin: true,
  },
}, {
  path: '/fkadmin/mfc-fight-events',
  name: 'mfc-fight-events',
  component: () => import('@/views/admin/MFCFightEvents.vue'),
  meta: {
    admin: true,
  },
}, {
  path: '/fkadmin/mfc-fight-results',
  name: 'mfc-fight-results',
  component: () => import('@/views/admin/MFCFightResults.vue'),
  meta: {
    admin: true,
  },
},

  {
    path: '/fkadmin/prize-wheel',
    name: 'prize-wheel-admin',
    component: () => import('@/views/admin/WheelSpins.vue'),
    meta: {
      admin: true,
    },
  }, {
    path: '/fkadmin/ufc-strike-reports',
    name: 'ufc-strike-reports',
    component: () => import('@/views/admin/UfcStrikeReports.vue'),
    meta: {
      admin: true,
    },
  }, {
    path: '/fkadmin/mfc-tournaments',
    name: 'mfc-tournaments-admin',
    component: () => import('@/views/admin/MfcTournaments.vue'),
    meta: {
      admin: true,
    },
  }, {
    path: '/403',
    name: '403',
    component: () => import('@/views/403.vue'),
    meta: {
      public: true,
    },
  }, {
    path: '/demo-video',
    redirect: '/',
  }, {
    path: '/news',
    name: 'news',
    component: () => import('@/views/pages/News.vue'),
    meta: {
      public: true,
    },
  }, {
    path: '/prize-wheel',
    name: 'prize-wheel',
    component: () => import('@/views/pages/Wheel.vue'),
    meta: {
      public: true,
    },
  }, {
    path: '/search',
    name: 'search',
    component: () => import('@/views/pages/Search.vue'),
    meta: {
      public: true,
    },
  }, {
    path: '*',
    redirect: 'error-404',
  },]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  const user = JSON.parse(localStorage.getItem('user'))
  if (to.matched.some(record => record.meta.admin)) {
    if (user.profile?.admin) {
      if (dbFunctions.checkAdmin(user)) {
        next()
      } else {
        router.push('/error-404')
      }
    } else {
      router.push('/error-404')
    }
  } else if (to.matched.some(record => record.meta.public)) {
    next()
  } else if (!user) {
    router.push('/join')
  } else {
    if (to.name === 'logout') {
      router.push('/dashboard')
    }
    next()
  }
})

router.onError(error => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    console.log('Loading chunk failed..old cached? Reloaded')
    window.location.reload()
  }
})

export default router
