<template>
  <div id="achievementAnimation" class="text-center">
    <div class="achievement-wrapper">
      <div class="achievement-super">
        <div class="achievement-body">
          <p class="achievement-text">Achievement: {{ achievement.title }}</p>
          <p class="achievement-subtext">{{ achievement.desc }}</p>
        </div>
        <div class="achievement-title">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
               xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               width="32" height="32" viewBox="0 0 512 512" enable-background="new 0 0 512 512"
               xml:space="preserve"
          >
					<path fill="#fff" d="M479.863,103.342c-0.051-2.833-0.096-5.279-0.096-7.342h-80.835c1.56-34.617,0.512-64,0.512-64s-95.591,0-142.568,0
	c-0.337,0-0.669,0.022-1,0.056c-0.33-0.034-0.662-0.056-1-0.056c-46.977,0-142.931,0-142.931,0s-1.048,29.383,0.512,64H32.232H32
	v20.548c0,0.114,0,0.228,0,0.342V128h0.161c0.811,26.096,4.98,60.999,22.333,96.729c14.718,30.307,35.912,55.664,62.996,75.367
	c22.422,16.312,48.041,28.064,76.205,35.084C209.96,352.539,226,362.109,240,365.957v35.625C238,412.165,225.86,448,141.234,448H128
	v32h256v-32h-13.178C271.538,448,272,398.666,272,398.666v-32.714c14-3.843,29.73-13.374,45.91-30.644
	c28.369-7.004,54.072-18.801,76.633-35.213c27.082-19.703,48.262-45.06,62.98-75.367
	C481.203,175.967,480.326,128.723,479.863,103.342z M83.262,210.745C68.802,180.966,65.018,150.996,64.187,128h50.487
	c0.868,8.914,1.966,17.701,3.356,25.98c8.513,50.709,20.213,95.493,42.354,135.009C126.546,271.848,99.97,245.149,83.262,210.745z
	M428.737,210.745c-16.807,34.61-43.603,61.421-77.729,78.55c22.215-39.591,33.816-84.475,42.352-135.314
	c1.39-8.28,2.488-17.067,3.356-25.98h51.096C446.981,150.996,443.198,180.966,428.737,210.745z"
          />
				</svg>
        </div>
      </div>
    </div>
    <audio
      id="acaudio"
      ref="acaudio"
      src="@/assets/sounds/Achievement.mp3"
    ></audio>
  </div>
</template>

<script>
import dbFunctions from '@/db'

export default {
  props: {
    delay: Number,
    achievement: {
      id: String,
      show: Boolean,
      title: String,
      desc: String,
      data: Array,
      color: String,
      sound: String,
      icon: String,
    },
  },
  async mounted() {
    if (!this.achievement.read) {
      this.startAnimation()
    }

  },
  methods: {
    saveRead() {
      return new Promise((resolve) => {
        setTimeout(async () => {
          await dbFunctions.achievementRead(this.achievement.id)
          resolve()
        }, 6000)
      })
    },
    async startAnimation() {
      const achievementBody = document.querySelector('.achievement-body')
      const achievementWrapper = document.querySelector('.achievement-wrapper')
      achievementBody.style.backgroundColor = this.achievement.color || '#2196F3'
      achievementWrapper.classList.remove('animation')
      setTimeout(() => {
        let audio = document.getElementById('acaudio')
        audio.src = require('@/assets/sounds/' + this.achievement.sound)
        audio.volume = 0.1
        audio.play()
        achievementWrapper.classList.add('animation')
        this.saveRead()
      }, 2000)

    }
  },
}
</script>
<style scoped>

.achievement-wrapper {
  position: fixed;
  bottom: -100px;
  left: 0;
  right: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.achievement-super {
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.achievement-body {
  position: relative;
  -webkit-transform: translateY(64px);
  transform: translateY(64px);
  margin: 0 auto;
  width: 64px;
  overflow: hidden;
  height: 64px;
  line-height: 24px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  border-radius: 32px;
  background-color: #00aeef;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 40%,
    rgba(255, 255, 255, 0.2) 60%,
    rgba(255, 255, 255, 0) 100%
  );
  background-repeat: no-repeat;
  background-size: 50% 100%;
  background-position: -100%;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.achievement-body .achievement-text {
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  opacity: 0;
  margin: 0;
}

.achievement-body .achievement-subtext {
  color: #f5f5f5;
  font-size: 14px;
  font-weight: 300;
  opacity: 0;
  margin: 0;
}

.achievement-title {
  background: rgba(255, 255, 255, 0);
  width: 64px;
  height: 64px;
  border-radius: 32px;
  text-align: center;
  line-height: 64px;
  color: #fff;
  margin: 0 auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 16px;
}

#achievementAnimation .achievement-wrapper.animation {
  z-index: 999999999;
}

.achievement-wrapper.animation {
  animation: slideUp 750ms cubic-bezier(0.75, -0.5, 0, 1.75) 0s 1 forwards,
  slideDown 750ms cubic-bezier(0.75, -0.5, 0, 1.75) 5450ms 1 forwards;
  -webkit-animation: slideUp 750ms cubic-bezier(0.75, -0.5, 0, 1.75) 0s 1 forwards,
  slideDown 750ms cubic-bezier(0.75, -0.5, 0, 1.75) 5450ms 1 forwards;
}

.achievement-wrapper.animation .achievement-title {
  animation: slideLeft 750ms ease 650ms 1 forwards,
  setColor 750ms ease 650ms 1 forwards, ripple 500ms ease 1200ms 1,
  slideRight 750ms ease 4750ms 1 forwards,
  removeColor 750ms ease 4750ms 1 forwards;
  -webkit-animation: slideLeft 750ms ease 650ms 1 forwards,
  setColor 750ms ease 650ms 1 forwards, ripple 500ms ease 1200ms 1,
  slideRight 750ms ease 4750ms 1 forwards,
  removeColor 750ms ease 4750ms 1 forwards;
}

.achievement-wrapper.animation .achievement-body {
  animation: expand 750ms ease 650ms 1 forwards,
  contract 750ms ease 4750ms 1 forwards, shimmer 1500ms linear 1400ms 2;
  -webkit-animation: expand 750ms ease 650ms 1 forwards,
  contract 750ms ease 4750ms 1 forwards, shimmer 1500ms linear 1400ms 2;
}

.achievement-wrapper.animation .achievement-text {
  animation: fadeInUp 550ms ease 1250ms 1 forwards,
  fadeOutUp 550ms ease 4250ms 1 forwards;
  -webkit-animation: fadeInUp 550ms ease 1250ms 1 forwards,
  fadeOutUp 550ms ease 4250ms 1 forwards;
}

.achievement-wrapper.animation .achievement-subtext {
  animation: fadeInUp 550ms ease 1350ms 1 forwards,
  fadeOutUp 550ms ease 4350ms 1 forwards;
  -webkit-animation: fadeInUp 550ms ease 1350ms 1 forwards,
  fadeOutUp 550ms ease 4350ms 1 forwards;
}

@-webkit-keyframes slideUp {
  0% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
  }
  100% {
    transform: translateY(-135px);
    -webkit-transform: translateY(-135px);
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
  }
  100% {
    transform: translateY(-135px);
    -webkit-transform: translateY(-135px);
  }
}

@-webkit-keyframes slideDown {
  100% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
  }
  0% {
    transform: translateY(-135px);
    -webkit-transform: translateY(-135px);
  }
}

@keyframes slideDown {
  100% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
  }
  0% {
    transform: translateY(-135px);
    -webkit-transform: translateY(-135px);
  }
}

@-webkit-keyframes setColor {
  0% {
    background: rgba(255, 255, 255, 0);
  }
  100% {
    background: rgba(255, 255, 255, 0.2);
  }
}

@keyframes setColor {
  0% {
    background: rgba(255, 255, 255, 0);
  }
  100% {
    background: rgba(255, 255, 255, 0.2);
  }
}

@-webkit-keyframes removeColor {
  100% {
    background: rgba(255, 255, 255, 0);
  }
  0% {
    background: rgba(255, 255, 255, 0.2);
  }
}

@keyframes removeColor {
  100% {
    background: rgba(255, 255, 255, 0);
  }
  0% {
    background: rgba(255, 255, 255, 0.2);
  }
}

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translate(calc(-48vw + 32px));
    transform: translate(calc(-48vw + 32px));
  }
}

@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translate(calc(-48vw + 32px));
    transform: translate(calc(-48vw + 32px));
  }
}

@-webkit-keyframes slideRight {
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  0% {
    -webkit-transform: translate(calc(-48vw + 32px));
    transform: translate(calc(-48vw + 32px));
  }
}

@keyframes slideRight {
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  0% {
    -webkit-transform: translate(calc(-48vw + 32px));
    transform: translate(calc(-48vw + 32px));
  }
}

@-webkit-keyframes expand {
  0% {
    width: 64px;
  }
  100% {
    width: 96vw;
    padding: 8px 40px;
  }
}

@keyframes expand {
  0% {
    width: 64px;
  }
  100% {
    width: 96vw;
    padding: 8px 40px;
  }
}

@-webkit-keyframes contract {
  100% {
    width: 64px;
  }
  0% {
    width: 96vw;
    padding: 8px 40px;
  }
}

@keyframes contract {
  100% {
    width: 64px;
  }
  0% {
    width: 96vw;
    padding: 8px 40px;
  }
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

@-webkit-keyframes ripple {
  100% {
    box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.08);
  }
  0% {
    box-shadow: inset 0 0 5px 50px rgba(0, 0, 0, 0);
  }
}

@keyframes ripple {
  100% {
    box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.08);
  }
  0% {
    box-shadow: inset 0 0 5px 50px rgba(0, 0, 0, 0);
  }
}

@-webkit-keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

@media only screen and (min-width: 480px) {
  @-webkit-keyframes slideLeft {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    100% {
      -webkit-transform: translate(-150px);
      transform: translate(-150px);
    }
  }

  @keyframes slideLeft {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    100% {
      -webkit-transform: translate(-150px);
      transform: translate(-150px);
    }
  }

  @-webkit-keyframes slideRight {
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    0% {
      -webkit-transform: translate(-150px);
      transform: translate(-150px);
    }
  }

  @keyframes slideRight {
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    0% {
      -webkit-transform: translate(-150px);
      transform: translate(-150px);
    }
  }

  @-webkit-keyframes expand {
    0% {
      width: 64px;
    }
    100% {
      width: 364px;
      padding: 8px 40px;
    }
  }

  @keyframes expand {
    0% {
      width: 64px;
    }
    100% {
      width: 364px;
      padding: 8px 40px;
    }
  }

  @-webkit-keyframes contract {
    100% {
      width: 64px;
    }
    0% {
      width: 364px;
      padding: 8px 40px;
    }
  }

  @keyframes contract {
    100% {
      width: 64px;
    }
    0% {
      width: 364px;
      padding: 8px 40px;
    }
  }
}

</style>
