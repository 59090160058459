require('./overrides.scss')

export default {
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: '#9ee3fc',
        accent: '#0d6efd',
        secondary: '#8A8D93',
        success: '#34de9f',
        info: '#16B1FF',
        warning: '#f79803',
        error: '#FF4C51',
        anchor: '#eae4f0',
      },
    },
  },
}
