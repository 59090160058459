<template>
  <v-app>
    <v-main :class="user.isloggedin === 'true' ? 'backgroundDefault' : 'backgroundOctagon' ">
      <router-view></router-view>
    </v-main>

    <v-footer
      color="transparent"
      class="pa-0"
    >
      <div class="w-full mb-4 mt-4 mr-2">
        <p class="text-xs text-center mb-0 pb-0">MoMMA Game is independently developed by a company that is not related, endorsed or sponsored by UFC, Endeavor or Zuffa LLC. Reference to any products, services, data or hyperlinks by trade name, trademark or otherwise does not necessarily constitute or imply endorsement, sponsorship or recommendation for or by MoMMA Game.</p>
        <div class="mx-2 d-flex justify-end">
           <span class="mr-2">
            <a
              href="https://mommagame.com/news"
              class="text-decoration-none text-xs"
            >News</a>
          </span>
          <span class="mr-2">
            <a
              href="https://support.mommagame.com/"
              target="_blank"
              class="text-decoration-none text-xs"
            >Support</a>
          </span>
          <span class="mr-2">
            <a
              href="https://support.mommagame.com/en/article/terms-of-service-qhnr7c/"
              target="_blank"
              class="text-decoration-none text-xs"
            >Terms of Service</a>
          </span>
          <span clsss="mr-2">
            <a
              href="https://support.mommagame.com/en/article/privacy-policy-hlxqnr/"
              target="_blank"
              class="text-decoration-none text-xs"
            >Privacy Policy</a>
          </span>
          <span>
          <a
            href="https://discord.gg/X2qvCm4ufu"
            target="_blank"
            class="text-decoration-none text-xs ml-2"
          ><v-icon>{{ icons.mdiDiscord }}</v-icon></a>
          </span>
          <span>
            <a
              href="https://twitter.com/mastersofmma"
              target="_blank"
              class="text-decoration-none text-xs ml-2"
            ><v-icon>{{ icons.mdiTwitter }}</v-icon></a>
          </span>
          <span>
            <a
              href="https://www.instagram.com/masters_of_mma/"
              target="_blank"
              class="text-decoration-none text-xs ml-2"
            ><v-icon>{{ icons.mdiInstagram }}</v-icon></a>
          </span>
          <span class="d-sm-inline d-none copyright mr-2 ml-2 mt-1 text-sm">
            &copy; {{ new Date().getFullYear() }} <a
            href="https://mommagame.com"
            class="text-decoration-none "
            target="_blank"
          >Masters of MMA</a>
          </span>
        </div>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import {
  mdiTwitter,
  mdiInstagram,
  mdiDiscord,
} from '@mdi/js'

export default {
  setup() {
    const user = {
      name: '',
      email: '',
      password: '',
      isLoggedin: false,
    }

    return {
      user,
      icons: {
        mdiTwitter,
        mdiInstagram,
        mdiDiscord
      },
    }
  },
}
</script>

<style scoped>
footer.v-footer.pa-0.v-sheet.theme--dark.transparent {
  background: #1c2956 !important;
}
</style>
